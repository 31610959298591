
<template>
<div>
  <div v-if="!isLoading" class="row gy-2">
    <div v-if="lawyer" class="col-12">
      <h5 class="mb-3">Lawyer Details</h5>
      <div class="row g-3">
        <div class="col-md-4 order-md-2">
          <div class="position-sticky" style="top: 90px;">
            <div class="card  bg-white overflow-hidden">
              <div class="card-body">
                <div class="">
                  <div class="profile-user position-relative d-inline-block mx-auto mb-4">
                    <img :src="absoluteUrl(lawyer.image)"
                      class="rounded-circle avatar-xl img-thumbnail user-profile-image" alt="user-profile-image" />
                  </div>
                  <h5 class="fs-16 mb-1">
                    {{lawyer.first_name}} {{lawyer.last_name}}
                  </h5>
                  <p class="mb-0"> <span v-if="lawyer.role" class="text-capitalize">{{lawyer.role.name}}</span> / Joined on {{$filters.date(lawyer.created_at, 1)}}</p>
                </div>
              </div>
              <div class="circles-container" ><div class="circle-back" ></div><div class="circle-front" ></div></div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="row g-3">
                <div class="col-sm-6 col-md-4">
                    <label class="form-label fw-bold">First name</label>
                    <div>{{lawyer.first_name}}</div>
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-label fw-bold">Middle name</label>
                    <div>{{lawyer.middle_name}}</div>
                </div>
                <div class="col-sm-6 col-md-4">
                    <label class="form-label fw-bold">Last name</label>
                    <div>{{lawyer.last_name}}</div>
                </div>
                <div class="col-sm-6">
                    <label class="form-label fw-bold">Email</label>
                    <div>{{lawyer.email}}</div>
                </div>
                <div class="col-sm-6">
                    <label class="form-label fw-bold">Phone</label>
                    <div>{{lawyer.mobile}}</div>
                </div>
                <div class="col-12">
                    <label class="form-label fw-bold">Location/Address</label>
                    <div>{{lawyer.address}}</div>
                </div>
                <div class="col-12 col-sm-4">
                    <label class="form-label fw-bold">Local Government</label>
                    <div v-if="lawyer.lga">{{lawyer.lga.name}}</div>
                </div>
                <div class="col-6 col-sm-4">
                    <label class="form-label fw-bold">State</label>
                    <div v-if="lawyer.state">{{lawyer.state.name}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="pt-3">
            <lawyer-cases  />
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
</div>
</template>
<script>

import IsLoading from '@/components/IsLoading.vue'
import LawyerCases from './LawyerCases.vue'

export default {
  name: "user-show",
  components: {
    IsLoading,
    LawyerCases,
  },
  data() {
    return {
      isLoading: true,
      lawyer: null
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
  },
  methods:{
    fetchItem(){
      this.isLoading = true
      this.$http.get(`/lawyers/${this.$route.params.lawyerId}/show`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.lawyer = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    }
  },
  created() {
    this.fetchItem()
  }
}

</script>
<style scoped>
.user-photo{
 width:10rem;
}
.circles-container {
    position: absolute;
    bottom: 0;
    top:0;
    width: 20%;
    right: -20%;
}

.circle-back, .circle-front{
    background-color: #e1f6e2;
    position: relative;
    width: 200%;
    padding-top: 200%;
    padding-bottom: 0;
    border-radius: 100%;
    display: inline-block
}
.circle-back {
    opacity: .6;
    z-index: 3;
    margin-left: -108%;
    margin-top: 0
}

.circle-front {
    opacity: .5;
    margin-bottom: 50%;
    margin-left: -209%;
    z-index: 4
}
</style>
